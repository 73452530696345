<template>
  <header class="ya-te-header border-b border-gray-300">
    <div class="co-container-lg navbar h-full py-0 pl-4 pr-0 lg:px-4">
      <div
        class="flex w-full flex-row items-center justify-between lg:mr-auto lg:w-auto"
        style="height: 70px"
      >
        <a
          class="ya-te-navbar-brand h-full"
          href="https://ly-academy.yahoo.co.jp/tech/"
        >
          <img
            class="te-top-cover-image-title w-full"
            src="~/assets/images/YaTe/YaTeCommon/Logo/logo_ly.svg"
            alt="LINEヤフーテックアカデミー"
            width="326"
            height="70"
          >
        </a>
      </div>
    </div>
  </header>
</template>

<style scoped>
.ya-te-header {
  z-index: 1030;
  width: 100%;
  height: 70px;
  background-color: #fff;
}

.ya-te-navbar-brand {
  width: 14.375rem;
  padding-top: 1.65rem;
  @media (min-width: 768px) {
    width: 20.375rem;
    padding-top: 1.35rem;
  }
  @media (max-width: 374px) {
    width: 10.5rem;
  }
}
</style>
